<template>
  <div class="rodo-page">
    <RodoComponent/>
  </div>
</template>


<script>
import RodoComponent from "@/components/RodoComponent.vue";
export default {
  name: "RodoPage",
  components: {
    RodoComponent,
  },
};
</script>
